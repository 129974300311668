*{
    text-align: center;
}

.list-wrapper{
    margin-bottom: 10vh;;
    list-style: none;
    color: blue;
}

.list-wrapper li{
    padding: .4vh;
    color:navy;
}

.genre-links{
    color: navy;
    font-family: 'Arvo', serif;
    text-decoration: none;
    font-size: 3.5vh;
}

.genre-links:visted{
    color:navy;
}

.genre-links:hover{
    color:green;
    text-decoration:underline;
}