
.gallery{
    display: flex;
    flex-direction: column;
    margin-top: 2vh;
    margin-left: 4vw;
    margin-right: 4vw;
}


.gallery-image{
    width: 9vw;
    height: 28vh;
    padding:2px;

}
