/* phone-sized */
.slider{
    position:relative;
    display:flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
}

.image{
    width: 8rem;
    height: 15rem;
}

.right-arrow{
    position: absolute;
    top: 50%;
    right: 32px;
    font-size: 2rem;
    color:navy;
    z-index:10;
    cursor: pointer;
    user-select:none;
}

.left-arrow{
    position: absolute;
    top: 50%;
    left: 32px;
    font-size: 2rem;
    color:navy;
    z-index:10;
    cursor: pointer;
    user-select:none;
}

.slide{
    opacity: 0;
    transition-duration: 1s ease;
}

.slide.active{
    opacity: 1;
    transition-duration: 1s ;
    transform: scale(1.08);
}

/*tablet-sized*/
@media(min-width: 641px) and (max-width: 900){

    .image{
        width: 10rem;
        height: 18rem;
    }
}

@media(min-width: 901px){
    .image{
        width:12rem;
        height:20rem;
    }
}




