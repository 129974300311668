/*desktop - 961px+ */

.navbar {
  background-color: gold;
  height: 12vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 0;
  z-index: 999;
  align-items: center;
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  max-width: 1500px;
}

 .nav-logo {
  color: navy;
  font-weight: bold;
  justify-self: start;
  margin-left: 3vw;
  cursor: pointer;
  text-decoration: none;
  font-size: 4vh;
  display: flex;
  align-items: center;
  font-family: 'Arvo', serif;
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(6, auto);
  grid-gap: 1vw;
  list-style: none;
  text-align: center;
  width: 60vw;
  justify-content: end;
  margin-right: 2rem;
}

.nav-item {
  height: 80px;
}

.nav-links {
  color: navy;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem .5rem;
  height: 100%;
  font-family: 'Arvo', serif;
  font-size: 1.25rem;

}

.nav-links:hover {
  text-decoration:  underline 3px solid darkolivegreen;
  transition: all 0.2s ease-out;
}

.menu-icon {
  display: none;
}

.web-logo{
  height: 10vh;
  width: 10vh;
}


/*mobile/ tablet 0 - 960px */
@media screen and (max-width: 1200px) {

  .navbar{
    margin-top: 5vh;
  }
  
  .NavbarItems {
    position: relative;;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 12vh;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    
  }

  .nav-menu.active {
    background: darkolivegreen;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding-top: 4vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .nav-links:hover {
    background-color:navy;
    color:white;
    border-radius: 0;
    border-bottom: none;
  }

  .nav-logo {
    display:none;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .bars-active{  
    color:navy;
    right: 2vw;
    font-size: 3vh;
    height: 3vh;
    width: 3vh;
    margin-top: 1.5vh;

  }

  .times-active{
    color:navy;
    right: 2vw;
    font-size: 3vh;
    height: 3vh;
    width: 3vh;
    margin-top: 1.5vh;
  }

  .bars-inactive{
    display:none;
  }

  .times-inactive{
    display:none;
  }

  .web-logo{
  height: 15vh;
  width: 15vh;
  }
}