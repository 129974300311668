* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.router-wrapper{
  background-color: rgb(227, 234, 255);
}



@media(min-width: 0px) and (max-width: 640px){

  .carousel-wrapper{
    align-items: center;
    padding-bottom: 5vw;
  }

  .HomeGallery{
    display: none;
  }

  .gallery-wrapper{
    display:none;
  }
  
}


/*tablet sized*/
@media(min-width: 641px) and (max-width: 1024px){

  .gallery-wrapper{
    display:none;
  }

  .HomeGallery{
    display:none;
  }

  .gal-pic{
    height: 15rem;
    width: 8rem;
  }

}


/*desktop*/
@media(min-width: 1025px){

  .home-wrapper{
    display:grid;
    grid-template-columns: 1fr 10fr 1fr;
  }

  .carousel-wrapper{
    display: none;
  }

  .text-wrapper{
    margin: 0 10vw;

  }
  

}
